@import '../variables';

.is-multiselect-checked {
  background-color: $main-theme-color-dark;
  color: $header-icon-fill-stroke-color;
}

.is-active:active {
  background-color: $main-theme-color-dark;
}

.bundle-product-name-fm_radio {
  width: 30%;
  cursor: pointer;
  max-width: 80px;
  overflow: hidden;
  word-break: break-word;
  white-space: pre-wrap;
}

.rotT_fms {
  transform: rotate(180deg);
}

.multiselect-style input[type="checkbox"] {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  width: 18px;
  height: 18px;
  margin: 0;
  margin-right: 1px;
  display: block;
  float: left;
  position: relative;
  cursor: pointer;
}

.multiselect-style input[type="checkbox"]:after {
  content: "";
  vertical-align: middle;
  text-align: center;
  line-height: 13px;
  position: absolute;
  cursor: pointer;
  width: 18px;
  height: 18px;
  left: 0;
  top: 0;
  font-size: 15px;
  border: 1px solid #707070;
  background: white;
}

.multiselect-style input[type="checkbox"]:checked:after {
  background: #707070;
  content: "\2714";
  color: #fff;
}

@media screen and (max-width: 430px) {
  .multiselect-style {
    width: unset !important;
  }
}
