@import '../variables';
@import '../mixins/direction';


$local-margin: 8px;

.filter-list__counter {
  padding-top: 3px;
  font-size: 12px;
  line-height: 12px;
  color: $card-font-muted-color;

  @include direction {
    #{$margin-inline-start}: 12px;
  }
}
