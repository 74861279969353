@import '../variables';


.widget-aboutus {
  border-radius: 4px;

  padding: 28px;

  @media (max-width: 479px) {
    padding: 20px;
  }
}

.widget-aboutus__socials {
  display: block;
  margin-top: 20px;
}
