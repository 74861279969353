@import "../variables";
@import "../functions";

.site-footer {
  padding: 34px 0;
  background-color: #faf7f7;
}

@media screen and (max-width: 768px) {
  .site-footer {
    height: max-content;
  }


}

.site-footer__bottom {
  height: 54px;
  border-top: $footer-copyright-border;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.site-footer__copyright {
  font-size: 14px;

  a:hover {
    text-decoration: underline;
  }
}

.site-footer__payments {
  img {
    max-width: 100%;
  }
}

.contact-customhtml-fms {
  font-size: 35px;
  font-weight: 500;
  margin-bottom: 100px;
}

.fieldset {
  border: 0;
  margin: 0 0 40px;
  padding: 0;
}

.fieldset-params-fms {
  display: flex;
  justify-content: space-between;
  gap: 10px;

  .contact_field {
    width: calc((100% - 20px) / 3);

    @media screen and (max-width: 991px) {
      width: 100%;
    }
  }
}

.contact-with-us {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.field .email .required {
  display: inline-block;
  margin-right: 31px;
  width: 30%;
}

.label-fms {
  margin-bottom: 20px;
}

.textt-area {
  white-space: pre-wrap;
  overflow-wrap: break-word;
}

.control {
  width: 30%;
}

.rounde-pills {
  color: white;

  border-radius: 8px !important;
}

.error {
  font-size: 13px;
  color: red;
}

.action-fms {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  button {
    background: $btn-primary-bg-color;
  }
}

.field input {
  border: 1px solid #fafafa;
  border-radius: 8px;
  background: #fafafa;
  padding-left: 8px;
  box-shadow: 0 0 0 2px #e5e5e5 inset;
}

.comment {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.comment-fms textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  border: 1px solid #fafafa;
  border-radius: 8px;
  background: #fafafa;
  width: 100%;
  padding: 5px 15px;
  box-shadow: 0 0 0 2px #e5e5e5 inset;
}

.submit-primary-fms {
  float: right;
  padding: 12px 70px;
  cursor: pointer;
  border-radius: 8px;
  margin-top: 10px;
  border: none !important;
  background: #43b02a;
}

.submit-primary-fms > span {
  color: white;
}

.topBar__item-title-fms {
  color: #3d464d;
}
