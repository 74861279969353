@import "../variables";
@import "../mixins/direction";


.mobilemenu__header {
  height: $mobilemenu-header-height + 1px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  border-bottom: 1px solid $mobilemenu-divider-color;
}

.mobilemenu__title {
  padding: 0 20px;
  font-weight: $font-weight-bold;
  flex-grow: 1;
}

.mobilemenu__close {
  border: none;
  background: transparent;
  height: $mobilemenu-header-height;
  width: $mobilemenu-header-height + 1px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  fill: $mobilemenu-close-button-default-color;
  transition: fill 0.2s;

  @include direction {
    #{$border-inline-start}: 1px solid $mobilemenu-divider-color;
  }

  &:focus {
    outline: none;
  }

  &:focus,
  &:hover {
    fill: $mobilemenu-close-button-hover-color;
  }
}