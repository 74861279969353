// -------------------------
// --- You can add your custom CSS here and it will overwrite template styles.
// -------------------------

@import url("https://fonts.googleapis.com/css2?family=Open+Sans&family=Poppins:wght@400;500&display=swap");
@import "variables";

/**************** Builder grid styles *********************/


.m_headerWrapper {
  display: none !important;
  transition: ease-in-out .3s;

  @media (max-width: 991px) {
    //overflow: hidden;
    display: block !important;
    position: sticky;
    top: 0;
    z-index: 200;
  }
}

.d_headerWrapper {
  display: block !important;
  top: 0;
  position: sticky;
  z-index: 200;

  @media (max-width: 991px) {
    display: none !important;
  }
}

//.err-block {
//  font-size: 13px;
//  color: red;
//}

.b-container {
  min-height: 0;
  margin-bottom: 0 !important;
  margin-top: 60px !important;
  padding: 0 15px !important;

}

.zg-row {
  display: flex;
}

.zg-grid-col {
  width: 100%;
  padding: 5px 10px;

  img {
    width: 100%;
  }
}

.zg-container {
  width: 100%;
  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: var(--bs-gutter-x, .75rem);
  margin-right: auto;
  margin-left: auto;
}

.zg-container-fluid {
  width: 100%;
  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: var(--bs-gutter-x, .75rem);
  margin-right: auto;
  margin-left: auto;
}

.zg-container-lg {
  width: 100%;
  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: var(--bs-gutter-x, .75rem);
  margin-right: auto;
  margin-left: auto;
}

.zg-container-md {
  width: 100%;
  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: var(--bs-gutter-x, .75rem);
  margin-right: auto;
  margin-left: auto;
}

.zg-container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: var(--bs-gutter-x, .75rem);
  margin-right: auto;
  margin-left: auto;
}

.zg-container-xl {
  width: 100%;
  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: var(--bs-gutter-x, .75rem);
  margin-right: auto;
  margin-left: auto;
}

.zg-container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, .75rem);
  padding-left: var(--bs-gutter-x, .75rem);
  margin-right: auto;
  margin-left: auto;
}

.zg-row {
  --bs-gutter-x: .5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -.5);
  margin-left: calc(var(--bs-gutter-x) * -.5);

  > * {
    box-sizing: border-box;
    flex-shrink: 0;
    width: 100%;
    max-width: 100%;
  }

  > .zg-dragforborder {
    box-sizing: border-box;
    background-color: $background-color_1;
    padding-right: calc(var(--bs-gutter-x) * .5);
    padding-left: calc(var(--bs-gutter-x) * .5);
    margin-top: var(--bs-gutter-y);
  }
}

.zg-draggable {
  .zg-dragforborder {
    padding: 5px;
    transition: 0.5s all ease-in;
  }
}

.zg-col {
  flex: 1 0 0;
}

.zg-row-cols-auto {
  > * {
    flex: 0 0 auto;
    width: auto;
  }
}

.zg-row-cols-1 {
  > * {
    flex: 0 0 auto;
    width: 100%;
  }
}

.zg-row-cols-2 {
  > * {
    flex: 0 0 auto;
    width: 50%;
  }
}

.zg-row-cols-3 {
  > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }
}

.zg-row-cols-4 {
  > * {
    flex: 0 0 auto;
    width: 25%;
  }
}

.zg-row-cols-5 {
  > * {
    flex: 0 0 auto;
    width: 20%;
  }
}

.zg-row-cols-6 {
  > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }
}

.zg-col-auto {
  flex: 0 0 auto;
  width: auto;
}

.zg-col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.zg-col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.zg-col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.zg-col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.zg-col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.zg-col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.zg-col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.zg-col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.zg-col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.zg-col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.zg-col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.zg-col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.zg-offset-1 {
  margin-left: 8.33333333%;
}

.zg-offset-2 {
  margin-left: 16.66666667%;
}

.zg-offset-3 {
  margin-left: 25%;
}

.zg-offset-4 {
  margin-left: 33.33333333%;
}

.zg-offset-5 {
  margin-left: 41.66666667%;
}

.zg-offset-6 {
  margin-left: 50%;
}

.zg-offset-7 {
  margin-left: 58.33333333%;
}

.zg-offset-8 {
  margin-left: 66.66666667%;
}

.zg-offset-9 {
  margin-left: 75%;
}

.zg-offset-10 {
  margin-left: 83.33333333%;
}

.zg-offset-11 {
  margin-left: 91.66666667%;
}

.zg-g-0 {
  --bs-gutter-x: 0;
  --bs-gutter-y: 0;
}

.zg-gx-0 {
  --bs-gutter-x: 0;
}

.zg-gy-0 {
  --bs-gutter-y: 0;
}

.zg-g-1 {
  --bs-gutter-x: 0.25rem;
  --bs-gutter-y: 0.25rem;
}

.zg-gx-1 {
  --bs-gutter-x: 0.25rem;
}

.zg-gy-1 {
  --bs-gutter-y: 0.25rem;
}

.zg-g-2 {
  --bs-gutter-x: 0.5rem;
  --bs-gutter-y: 0.5rem;
}

.zg-gx-2 {
  --bs-gutter-x: 0.5rem;
}

.zg-gy-2 {
  --bs-gutter-y: 0.5rem;
}

.zg-g-3 {
  --bs-gutter-x: 1rem;
  --bs-gutter-y: 1rem;
}

.zg-gx-3 {
  --bs-gutter-x: 1rem;
}

.zg-gy-3 {
  --bs-gutter-y: 1rem;
}

.zg-g-4 {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 1.5rem;
}

.zg-gx-4 {
  --bs-gutter-x: 1.5rem;
}

.zg-gy-4 {
  --bs-gutter-y: 1.5rem;
}

.zg-g-5 {
  --bs-gutter-x: 3rem;
  --bs-gutter-y: 3rem;
}

.zg-gx-5 {
  --bs-gutter-x: 3rem;
}

.zg-gy-5 {
  --bs-gutter-y: 3rem;
}

.zg-d-inline {
  display: inline !important;
}

.zg-d-inline-block {
  display: inline-block !important;
}

.zg-d-block {
  display: block !important;
}

.zg-d-grid {
  display: grid !important;
}

.zg-d-table {
  display: table !important;
}

.zg-d-table-row {
  display: table-row !important;
}

.zg-d-table-cell {
  display: table-cell !important;
}

.zg-d-flex {
  display: flex !important;
}

.zg-d-inline-flex {
  display: inline-flex !important;
}

.zg-d-none {
  display: none !important;
}

.zg-flex-fill {
  flex: 1 1 auto !important;
}

.zg-flex-row {
  flex-direction: row !important;
}

.zg-flex-column {
  flex-direction: column !important;
}

.zg-flex-row-reverse {
  flex-direction: row-reverse !important;
}

.zg-flex-column-reverse {
  flex-direction: column-reverse !important;
}

.zg-flex-grow-0 {
  flex-grow: 0 !important;
}

.zg-flex-grow-1 {
  flex-grow: 1 !important;
}

.zg-flex-shrink-0 {
  flex-shrink: 0 !important;
}

.zg-flex-shrink-1 {
  flex-shrink: 1 !important;
}

.zg-flex-wrap {
  flex-wrap: wrap !important;
}

.zg-flex-nowrap {
  flex-wrap: nowrap !important;
}

.zg-flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.zg-justify-content-start {
  justify-content: flex-start !important;
}

.zg-justify-content-end {
  justify-content: flex-end !important;
}

.zg-justify-content-center {
  justify-content: center !important;
}

.zg-justify-content-between {
  justify-content: space-between !important;
}

.zg-justify-content-around {
  justify-content: space-around !important;
}

.zg-justify-content-evenly {
  justify-content: space-evenly !important;
}

.zg-algin-items-start {
  align-items: flex-start !important;
}

.zg-algin-items-end {
  align-items: flex-end !important;
}

.zg-algin-items-center {
  align-items: center !important;
}

.zg-algin-items-baseline {
  align-items: baseline !important;
}

.zg-algin-items-stretch {
  align-items: stretch !important;
}

.zg-algin-content-start {
  align-content: flex-start !important;
}

.zg-algin-content-end {
  align-content: flex-end !important;
}

.zg-algin-content-center {
  align-content: center !important;
}

.zg-algin-content-between {
  align-content: space-between !important;
}

.zg-algin-content-around {
  align-content: space-around !important;
}

.zg-algin-content-stretch {
  align-content: stretch !important;
}

.zg-algin-self-auto {
  align-self: auto !important;
}

.zg-algin-self-start {
  align-self: flex-start !important;
}

.zg-algin-self-end {
  align-self: flex-end !important;
}

.zg-algin-self-center {
  align-self: center !important;
}

.zg-algin-self-baseline {
  align-self: baseline !important;
}

.zg-algin-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .zg-container {
    max-width: 540px;
  }
  .zg-container-sm {
    max-width: 540px;
  }
  .zg-col-sm {
    flex: 1 0 0;
  }
  .zg-row-cols-sm-auto {
    > * {
      flex: 0 0 auto;
      width: auto;
    }
  }
  .zg-row-cols-sm-1 {
    > * {
      flex: 0 0 auto;
      width: 100%;
    }
  }
  .zg-row-cols-sm-2 {
    > * {
      flex: 0 0 auto;
      width: 50%;
    }
  }
  .zg-row-cols-sm-3 {
    > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
  }
  .zg-row-cols-sm-4 {
    > * {
      flex: 0 0 auto;
      width: 25%;
    }
  }
  .zg-row-cols-sm-5 {
    > * {
      flex: 0 0 auto;
      width: 20%;
    }
  }
  .zg-row-cols-sm-6 {
    > * {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
  }
  .zg-col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .zg-col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .zg-col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .zg-col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .zg-col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .zg-col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .zg-col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .zg-col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .zg-col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .zg-col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .zg-col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .zg-col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .zg-col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .zg-offset-sm-0 {
    margin-left: 0;
  }
  .zg-offset-sm-1 {
    margin-left: 8.33333333%;
  }
  .zg-offset-sm-2 {
    margin-left: 16.66666667%;
  }
  .zg-offset-sm-3 {
    margin-left: 25%;
  }
  .zg-offset-sm-4 {
    margin-left: 33.33333333%;
  }
  .zg-offset-sm-5 {
    margin-left: 41.66666667%;
  }
  .zg-offset-sm-6 {
    margin-left: 50%;
  }
  .zg-offset-sm-7 {
    margin-left: 58.33333333%;
  }
  .zg-offset-sm-8 {
    margin-left: 66.66666667%;
  }
  .zg-offset-sm-9 {
    margin-left: 75%;
  }
  .zg-offset-sm-10 {
    margin-left: 83.33333333%;
  }
  .zg-offset-sm-11 {
    margin-left: 91.66666667%;
  }
  .zg-g-sm-0 {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
  }
  .zg-gx-sm-0 {
    --bs-gutter-x: 0;
  }
  .zg-gy-sm-0 {
    --bs-gutter-y: 0;
  }
  .zg-g-sm-1 {
    --bs-gutter-x: 0.25rem;
    --bs-gutter-y: 0.25rem;
  }
  .zg-gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }
  .zg-gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }
  .zg-g-sm-2 {
    --bs-gutter-x: 0.5rem;
    --bs-gutter-y: 0.5rem;
  }
  .zg-gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }
  .zg-gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }
  .zg-g-sm-3 {
    --bs-gutter-x: 1rem;
    --bs-gutter-y: 1rem;
  }
  .zg-gx-sm-3 {
    --bs-gutter-x: 1rem;
  }
  .zg-gy-sm-3 {
    --bs-gutter-y: 1rem;
  }
  .zg-g-sm-4 {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 1.5rem;
  }
  .zg-gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }
  .zg-gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }
  .zg-g-sm-5 {
    --bs-gutter-x: 3rem;
    --bs-gutter-y: 3rem;
  }
  .zg-gx-sm-5 {
    --bs-gutter-x: 3rem;
  }
  .zg-gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
  .zg-d-sm-inline {
    display: inline !important;
  }
  .zg-d-sm-inline-block {
    display: inline-block !important;
  }
  .zg-d-sm-block {
    display: block !important;
  }
  .zg-d-sm-grid {
    display: grid !important;
  }
  .zg-d-sm-table {
    display: table !important;
  }
  .zg-d-sm-table-row {
    display: table-row !important;
  }
  .zg-d-sm-table-cell {
    display: table-cell !important;
  }
  .zg-d-sm-flex {
    display: flex !important;
  }
  .zg-d-sm-inline-flex {
    display: inline-flex !important;
  }
  .zg-d-sm-none {
    display: none !important;
  }
  .zg-flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .zg-flex-sm-row {
    flex-direction: row !important;
  }
  .zg-flex-sm-column {
    flex-direction: column !important;
  }
  .zg-flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .zg-flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .zg-flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .zg-flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .zg-flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .zg-flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .zg-flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .zg-flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .zg-flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .zg-justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .zg-justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .zg-justify-content-sm-center {
    justify-content: center !important;
  }
  .zg-justify-content-sm-between {
    justify-content: space-between !important;
  }
  .zg-justify-content-sm-around {
    justify-content: space-around !important;
  }
  .zg-justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }
  .zg-algin-items-sm-start {
    align-items: flex-start !important;
  }
  .zg-algin-items-sm-end {
    align-items: flex-end !important;
  }
  .zg-algin-items-sm-center {
    align-items: center !important;
  }
  .zg-algin-items-sm-baseline {
    align-items: baseline !important;
  }
  .zg-algin-items-sm-stretch {
    align-items: stretch !important;
  }
  .zg-algin-content-sm-start {
    align-content: flex-start !important;
  }
  .zg-algin-content-sm-end {
    align-content: flex-end !important;
  }
  .zg-algin-content-sm-center {
    align-content: center !important;
  }
  .zg-algin-content-sm-between {
    align-content: space-between !important;
  }
  .zg-algin-content-sm-around {
    align-content: space-around !important;
  }
  .zg-algin-content-sm-stretch {
    align-content: stretch !important;
  }
  .zg-algin-self-sm-auto {
    align-self: auto !important;
  }
  .zg-algin-self-sm-start {
    align-self: flex-start !important;
  }
  .zg-algin-self-sm-end {
    align-self: flex-end !important;
  }
  .zg-algin-self-sm-center {
    align-self: center !important;
  }
  .zg-algin-self-sm-baseline {
    align-self: baseline !important;
  }
  .zg-algin-self-sm-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 768px) {
  .zg-container {
    max-width: 720px;
  }
  .zg-container-md {
    max-width: 720px;
  }
  .zg-container-sm {
    max-width: 720px;
  }
  .zg-col-md {
    flex: 1 0 0;
  }
  .zg-row-cols-md-auto {
    > * {
      flex: 0 0 auto;
      width: auto;
    }
  }
  .zg-row-cols-md-1 {
    > * {
      flex: 0 0 auto;
      width: 100%;
    }
  }
  .zg-row-cols-md-2 {
    > * {
      flex: 0 0 auto;
      width: 50%;
    }
  }
  .zg-row-cols-md-3 {
    > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
  }
  .zg-row-cols-md-4 {
    > * {
      flex: 0 0 auto;
      width: 25%;
    }
  }
  .zg-row-cols-md-5 {
    > * {
      flex: 0 0 auto;
      width: 20%;
    }
  }
  .zg-row-cols-md-6 {
    > * {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
  }
  .zg-col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .zg-col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .zg-col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .zg-col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .zg-col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .zg-col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .zg-col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .zg-col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .zg-col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .zg-col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .zg-col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .zg-col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .zg-col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .zg-offset-md-0 {
    margin-left: 0;
  }
  .zg-offset-md-1 {
    margin-left: 8.33333333%;
  }
  .zg-offset-md-2 {
    margin-left: 16.66666667%;
  }
  .zg-offset-md-3 {
    margin-left: 25%;
  }
  .zg-offset-md-4 {
    margin-left: 33.33333333%;
  }
  .zg-offset-md-5 {
    margin-left: 41.66666667%;
  }
  .zg-offset-md-6 {
    margin-left: 50%;
  }
  .zg-offset-md-7 {
    margin-left: 58.33333333%;
  }
  .zg-offset-md-8 {
    margin-left: 66.66666667%;
  }
  .zg-offset-md-9 {
    margin-left: 75%;
  }
  .zg-offset-md-10 {
    margin-left: 83.33333333%;
  }
  .zg-offset-md-11 {
    margin-left: 91.66666667%;
  }
  .zg-g-md-0 {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
  }
  .zg-gx-md-0 {
    --bs-gutter-x: 0;
  }
  .zg-gy-md-0 {
    --bs-gutter-y: 0;
  }
  .zg-g-md-1 {
    --bs-gutter-x: 0.25rem;
    --bs-gutter-y: 0.25rem;
  }
  .zg-gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }
  .zg-gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }
  .zg-g-md-2 {
    --bs-gutter-x: 0.5rem;
    --bs-gutter-y: 0.5rem;
  }
  .zg-gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }
  .zg-gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }
  .zg-g-md-3 {
    --bs-gutter-x: 1rem;
    --bs-gutter-y: 1rem;
  }
  .zg-gx-md-3 {
    --bs-gutter-x: 1rem;
  }
  .zg-gy-md-3 {
    --bs-gutter-y: 1rem;
  }
  .zg-g-md-4 {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 1.5rem;
  }
  .zg-gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }
  .zg-gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }
  .zg-g-md-5 {
    --bs-gutter-x: 3rem;
    --bs-gutter-y: 3rem;
  }
  .zg-gx-md-5 {
    --bs-gutter-x: 3rem;
  }
  .zg-gy-md-5 {
    --bs-gutter-y: 3rem;
  }
  .zg-d-md-inline {
    display: inline !important;
  }
  .zg-d-md-inline-block {
    display: inline-block !important;
  }
  .zg-d-md-block {
    display: block !important;
  }
  .zg-d-md-grid {
    display: grid !important;
  }
  .zg-d-md-table {
    display: table !important;
  }
  .zg-d-md-table-row {
    display: table-row !important;
  }
  .zg-d-md-table-cell {
    display: table-cell !important;
  }
  .zg-d-md-flex {
    display: flex !important;
  }
  .zg-d-md-inline-flex {
    display: inline-flex !important;
  }
  .zg-d-md-none {
    display: none !important;
  }
  .zg-flex-md-fill {
    flex: 1 1 auto !important;
  }
  .zg-flex-md-row {
    flex-direction: row !important;
  }
  .zg-flex-md-column {
    flex-direction: column !important;
  }
  .zg-flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .zg-flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .zg-flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .zg-flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .zg-flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .zg-flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .zg-flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .zg-flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .zg-flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .zg-justify-content-md-start {
    justify-content: flex-start !important;
  }
  .zg-justify-content-md-end {
    justify-content: flex-end !important;
  }
  .zg-justify-content-md-center {
    justify-content: center !important;
  }
  .zg-justify-content-md-between {
    justify-content: space-between !important;
  }
  .zg-justify-content-md-around {
    justify-content: space-around !important;
  }
  .zg-justify-content-md-evenly {
    justify-content: space-evenly !important;
  }
  .zg-algin-items-md-start {
    align-items: flex-start !important;
  }
  .zg-algin-items-md-end {
    align-items: flex-end !important;
  }
  .zg-algin-items-md-center {
    align-items: center !important;
  }
  .zg-algin-items-md-baseline {
    align-items: baseline !important;
  }
  .zg-algin-items-md-stretch {
    align-items: stretch !important;
  }
  .zg-algin-content-md-start {
    align-content: flex-start !important;
  }
  .zg-algin-content-md-end {
    align-content: flex-end !important;
  }
  .zg-algin-content-md-center {
    align-content: center !important;
  }
  .zg-algin-content-md-between {
    align-content: space-between !important;
  }
  .zg-algin-content-md-around {
    align-content: space-around !important;
  }
  .zg-algin-content-md-stretch {
    align-content: stretch !important;
  }
  .zg-algin-self-md-auto {
    align-self: auto !important;
  }
  .zg-algin-self-md-start {
    align-self: flex-start !important;
  }
  .zg-algin-self-md-end {
    align-self: flex-end !important;
  }
  .zg-algin-self-md-center {
    align-self: center !important;
  }
  .zg-algin-self-md-baseline {
    align-self: baseline !important;
  }
  .zg-algin-self-md-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 992px) {
  .zg-container {
    max-width: 960px;
  }
  .zg-container-lg {
    max-width: 960px;
  }
  .zg-container-md {
    max-width: 960px;
  }
  .zg-container-sm {
    max-width: 960px;
  }
  .zg-col-lg {
    flex: 1 0 0;
  }
  .zg-row-cols-lg-auto {
    > * {
      flex: 0 0 auto;
      width: auto;
    }
  }
  .zg-row-cols-lg-1 {
    > * {
      flex: 0 0 auto;
      width: 100%;
    }
  }
  .zg-row-cols-lg-2 {
    > * {
      flex: 0 0 auto;
      width: 50%;
    }
  }
  .zg-row-cols-lg-3 {
    > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
  }
  .zg-row-cols-lg-4 {
    > * {
      flex: 0 0 auto;
      width: 25%;
    }
  }
  .zg-row-cols-lg-5 {
    > * {
      flex: 0 0 auto;
      width: 20%;
    }
  }
  .zg-row-cols-lg-6 {
    > * {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
  }
  .zg-col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .zg-col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .zg-col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .zg-col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .zg-col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .zg-col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .zg-col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .zg-col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .zg-col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .zg-col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .zg-col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .zg-col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .zg-col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .zg-offset-lg-0 {
    margin-left: 0;
  }
  .zg-offset-lg-1 {
    margin-left: 8.33333333%;
  }
  .zg-offset-lg-2 {
    margin-left: 16.66666667%;
  }
  .zg-offset-lg-3 {
    margin-left: 25%;
  }
  .zg-offset-lg-4 {
    margin-left: 33.33333333%;
  }
  .zg-offset-lg-5 {
    margin-left: 41.66666667%;
  }
  .zg-offset-lg-6 {
    margin-left: 50%;
  }
  .zg-offset-lg-7 {
    margin-left: 58.33333333%;
  }
  .zg-offset-lg-8 {
    margin-left: 66.66666667%;
  }
  .zg-offset-lg-9 {
    margin-left: 75%;
  }
  .zg-offset-lg-10 {
    margin-left: 83.33333333%;
  }
  .zg-offset-lg-11 {
    margin-left: 91.66666667%;
  }
  .zg-g-lg-0 {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
  }
  .zg-gx-lg-0 {
    --bs-gutter-x: 0;
  }
  .zg-gy-lg-0 {
    --bs-gutter-y: 0;
  }
  .zg-g-lg-1 {
    --bs-gutter-x: 0.25rem;
    --bs-gutter-y: 0.25rem;
  }
  .zg-gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }
  .zg-gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }
  .zg-g-lg-2 {
    --bs-gutter-x: 0.5rem;
    --bs-gutter-y: 0.5rem;
  }
  .zg-gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }
  .zg-gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }
  .zg-g-lg-3 {
    --bs-gutter-x: 1rem;
    --bs-gutter-y: 1rem;
  }
  .zg-gx-lg-3 {
    --bs-gutter-x: 1rem;
  }
  .zg-gy-lg-3 {
    --bs-gutter-y: 1rem;
  }
  .zg-g-lg-4 {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 1.5rem;
  }
  .zg-gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }
  .zg-gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }
  .zg-g-lg-5 {
    --bs-gutter-x: 3rem;
    --bs-gutter-y: 3rem;
  }
  .zg-gx-lg-5 {
    --bs-gutter-x: 3rem;
  }
  .zg-gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
  .zg-d-lg-inline {
    display: inline !important;
  }
  .zg-d-lg-inline-block {
    display: inline-block !important;
  }
  .zg-d-lg-block {
    display: block !important;
  }
  .zg-d-lg-grid {
    display: grid !important;
  }
  .zg-d-lg-table {
    display: table !important;
  }
  .zg-d-lg-table-row {
    display: table-row !important;
  }
  .zg-d-lg-table-cell {
    display: table-cell !important;
  }
  .zg-d-lg-flex {
    display: flex !important;
  }
  .zg-d-lg-inline-flex {
    display: inline-flex !important;
  }
  .zg-d-lg-none {
    display: none !important;
  }
  .zg-flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .zg-flex-lg-row {
    flex-direction: row !important;
  }
  .zg-flex-lg-column {
    flex-direction: column !important;
  }
  .zg-flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .zg-flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .zg-flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .zg-flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .zg-flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .zg-flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .zg-flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .zg-flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .zg-flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .zg-justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .zg-justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .zg-justify-content-lg-center {
    justify-content: center !important;
  }
  .zg-justify-content-lg-between {
    justify-content: space-between !important;
  }
  .zg-justify-content-lg-around {
    justify-content: space-around !important;
  }
  .zg-justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }
  .zg-algin-items-lg-start {
    align-items: flex-start !important;
  }
  .zg-algin-items-lg-end {
    align-items: flex-end !important;
  }
  .zg-algin-items-lg-center {
    align-items: center !important;
  }
  .zg-algin-items-lg-baseline {
    align-items: baseline !important;
  }
  .zg-algin-items-lg-stretch {
    align-items: stretch !important;
  }
  .zg-algin-content-lg-start {
    align-content: flex-start !important;
  }
  .zg-algin-content-lg-end {
    align-content: flex-end !important;
  }
  .zg-algin-content-lg-center {
    align-content: center !important;
  }
  .zg-algin-content-lg-between {
    align-content: space-between !important;
  }
  .zg-algin-content-lg-around {
    align-content: space-around !important;
  }
  .zg-algin-content-lg-stretch {
    align-content: stretch !important;
  }
  .zg-algin-self-lg-auto {
    align-self: auto !important;
  }
  .zg-algin-self-lg-start {
    align-self: flex-start !important;
  }
  .zg-algin-self-lg-end {
    align-self: flex-end !important;
  }
  .zg-algin-self-lg-center {
    align-self: center !important;
  }
  .zg-algin-self-lg-baseline {
    align-self: baseline !important;
  }
  .zg-algin-self-lg-stretch {
    align-self: stretch !important;
  }
}

@media (min-width: 1200px) {
  .zg-container {
    max-width: 1140px;
  }
  .zg-container-lg {
    max-width: 1140px;
  }
  .zg-container-md {
    max-width: 1140px;
  }
  .zg-container-sm {
    max-width: 1140px;
  }
  .zg-container-xl {
    max-width: 1140px;
  }
  .zg-col-xl {
    flex: 1 0 0;
  }
  .zg-row-cols-xl-auto {
    > * {
      flex: 0 0 auto;
      width: auto;
    }
  }
  .zg-row-cols-xl-1 {
    > * {
      flex: 0 0 auto;
      width: 100%;
    }
  }
  .zg-row-cols-xl-2 {
    > * {
      flex: 0 0 auto;
      width: 50%;
    }
  }
  .zg-row-cols-xl-3 {
    > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
  }
  .zg-row-cols-xl-4 {
    > * {
      flex: 0 0 auto;
      width: 25%;
    }
  }
  .zg-row-cols-xl-5 {
    > * {
      flex: 0 0 auto;
      width: 20%;
    }
  }
  .zg-row-cols-xl-6 {
    > * {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
  }
  .zg-col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .zg-col-xl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .zg-col-xl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .zg-col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .zg-col-xl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .zg-col-xl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .zg-col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .zg-col-xl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .zg-col-xl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .zg-col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .zg-col-xl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .zg-col-xl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .zg-col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .zg-offset-xl-0 {
    margin-left: 0;
  }
  .zg-offset-xl-1 {
    margin-left: 8.33333333%;
  }
  .zg-offset-xl-2 {
    margin-left: 16.66666667%;
  }
  .zg-offset-xl-3 {
    margin-left: 25%;
  }
  .zg-offset-xl-4 {
    margin-left: 33.33333333%;
  }
  .zg-offset-xl-5 {
    margin-left: 41.66666667%;
  }
  .zg-offset-xl-6 {
    margin-left: 50%;
  }
  .zg-offset-xl-7 {
    margin-left: 58.33333333%;
  }
  .zg-offset-xl-8 {
    margin-left: 66.66666667%;
  }
  .zg-offset-xl-9 {
    margin-left: 75%;
  }
  .zg-offset-xl-10 {
    margin-left: 83.33333333%;
  }
  .zg-offset-xl-11 {
    margin-left: 91.66666667%;
  }
  .zg-g-xl-0 {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
  }
  .zg-gx-xl-0 {
    --bs-gutter-x: 0;
  }
  .zg-gy-xl-0 {
    --bs-gutter-y: 0;
  }
  .zg-g-xl-1 {
    --bs-gutter-x: 0.25rem;
    --bs-gutter-y: 0.25rem;
  }
  .zg-gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .zg-gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .zg-g-xl-2 {
    --bs-gutter-x: 0.5rem;
    --bs-gutter-y: 0.5rem;
  }
  .zg-gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .zg-gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .zg-g-xl-3 {
    --bs-gutter-x: 1rem;
    --bs-gutter-y: 1rem;
  }
  .zg-gx-xl-3 {
    --bs-gutter-x: 1rem;
  }
  .zg-gy-xl-3 {
    --bs-gutter-y: 1rem;
  }
  .zg-g-xl-4 {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 1.5rem;
  }
  .zg-gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .zg-gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .zg-g-xl-5 {
    --bs-gutter-x: 3rem;
    --bs-gutter-y: 3rem;
  }
  .zg-gx-xl-5 {
    --bs-gutter-x: 3rem;
  }
  .zg-gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
  .zg-d-xl-inline {
    display: inline !important;
  }
  .zg-d-xl-inline-block {
    display: inline-block !important;
  }
  .zg-d-xl-block {
    display: block !important;
  }
  .zg-d-xl-grid {
    display: grid !important;
  }
  .zg-d-xl-table {
    display: table !important;
  }
  .zg-d-xl-table-row {
    display: table-row !important;
  }
  .zg-d-xl-table-cell {
    display: table-cell !important;
  }
  .zg-d-xl-flex {
    display: flex !important;
  }
  .zg-d-xl-inline-flex {
    display: inline-flex !important;
  }
  .zg-d-xl-none {
    display: none !important;
  }
  .zg-flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .zg-flex-xl-row {
    flex-direction: row !important;
  }
  .zg-flex-xl-column {
    flex-direction: column !important;
  }
  .zg-flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .zg-flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .zg-flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .zg-flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .zg-flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .zg-flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .zg-flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .zg-flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .zg-flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .zg-justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .zg-justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .zg-justify-content-xl-center {
    justify-content: center !important;
  }
  .zg-justify-content-xl-between {
    justify-content: space-between !important;
  }
  .zg-justify-content-xl-around {
    justify-content: space-around !important;
  }
  .zg-justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }
  .zg-algin-items-xl-start {
    align-items: flex-start !important;
  }
}

@media (min-width: 1400px) {
  .zg-container {
    max-width: 1320px;
  }
  .zg-container-lg {
    max-width: 1320px;
  }
  .zg-container-md {
    max-width: 1320px;
  }
  .zg-container-sm {
    max-width: 1320px;
  }
  .zg-container-xl {
    max-width: 1320px;
  }
  .zg-container-xxl {
    max-width: 1320px;
  }
  .zg-col-xxl {
    flex: 1 0 0;
  }
  .zg-row-cols-xxl-auto {
    > * {
      flex: 0 0 auto;
      width: auto;
    }
  }
  .zg-row-cols-xxl-1 {
    > * {
      flex: 0 0 auto;
      width: 100%;
    }
  }
  .zg-row-cols-xxl-2 {
    > * {
      flex: 0 0 auto;
      width: 50%;
    }
  }
  .zg-row-cols-xxl-3 {
    > * {
      flex: 0 0 auto;
      width: 33.3333333333%;
    }
  }
  .zg-row-cols-xxl-4 {
    > * {
      flex: 0 0 auto;
      width: 25%;
    }
  }
  .zg-row-cols-xxl-5 {
    > * {
      flex: 0 0 auto;
      width: 20%;
    }
  }
  .zg-row-cols-xxl-6 {
    > * {
      flex: 0 0 auto;
      width: 16.6666666667%;
    }
  }
  .zg-col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }
  .zg-col-xxl-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }
  .zg-col-xxl-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }
  .zg-col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }
  .zg-col-xxl-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }
  .zg-col-xxl-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }
  .zg-col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }
  .zg-col-xxl-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }
  .zg-col-xxl-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }
  .zg-col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }
  .zg-col-xxl-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }
  .zg-col-xxl-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }
  .zg-col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }
  .zg-offset-xxl-0 {
    margin-left: 0;
  }
  .zg-offset-xxl-1 {
    margin-left: 8.33333333%;
  }
  .zg-offset-xxl-2 {
    margin-left: 16.66666667%;
  }
  .zg-offset-xxl-3 {
    margin-left: 25%;
  }
  .zg-offset-xxl-4 {
    margin-left: 33.33333333%;
  }
  .zg-offset-xxl-5 {
    margin-left: 41.66666667%;
  }
  .zg-offset-xxl-6 {
    margin-left: 50%;
  }
  .zg-offset-xxl-7 {
    margin-left: 58.33333333%;
  }
  .zg-offset-xxl-8 {
    margin-left: 66.66666667%;
  }
  .zg-offset-xxl-9 {
    margin-left: 75%;
  }
  .zg-offset-xxl-10 {
    margin-left: 83.33333333%;
  }
  .zg-offset-xxl-11 {
    margin-left: 91.66666667%;
  }
  .zg-g-xxl-0 {
    --bs-gutter-x: 0;
    --bs-gutter-y: 0;
  }
  .zg-gx-xxl-0 {
    --bs-gutter-x: 0;
  }
  .zg-gy-xxl-0 {
    --bs-gutter-y: 0;
  }
  .zg-g-xxl-1 {
    --bs-gutter-x: 0.25rem;
    --bs-gutter-y: 0.25rem;
  }
  .zg-gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }
  .zg-gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }
  .zg-g-xxl-2 {
    --bs-gutter-x: 0.5rem;
    --bs-gutter-y: 0.5rem;
  }
  .zg-gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }
  .zg-gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }
  .zg-g-xxl-3 {
    --bs-gutter-x: 1rem;
    --bs-gutter-y: 1rem;
  }
  .zg-gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }
  .zg-gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }
  .zg-g-xxl-4 {
    --bs-gutter-x: 1.5rem;
    --bs-gutter-y: 1.5rem;
  }
  .zg-gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }
  .zg-gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }
  .zg-g-xxl-5 {
    --bs-gutter-x: 3rem;
    --bs-gutter-y: 3rem;
  }
  .zg-gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }
  .zg-gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
  .zg-d-xxl-inline {
    display: inline !important;
  }
  .zg-d-xxl-inline-block {
    display: inline-block !important;
  }
  .zg-d-xxl-block {
    display: block !important;
  }
  .zg-d-xxl-grid {
    display: grid !important;
  }
  .zg-d-xxl-table {
    display: table !important;
  }
  .zg-d-xxl-table-row {
    display: table-row !important;
  }
  .zg-d-xxl-table-cell {
    display: table-cell !important;
  }
  .zg-d-xxl-flex {
    display: flex !important;
  }
  .zg-d-xxl-inline-flex {
    display: inline-flex !important;
  }
  .zg-d-xxl-none {
    display: none !important;
  }
  .zg-flex-xxl-fill {
    flex: 1 1 auto !important;
  }
  .zg-flex-xxl-row {
    flex-direction: row !important;
  }
  .zg-flex-xxl-column {
    flex-direction: column !important;
  }
  .zg-flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .zg-flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .zg-flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }
  .zg-flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }
  .zg-flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .zg-flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .zg-flex-xxl-wrap {
    flex-wrap: wrap !important;
  }
  .zg-flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }
  .zg-flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .zg-justify-content-xxl-start {
    justify-content: flex-start !important;
  }
  .zg-justify-content-xxl-end {
    justify-content: flex-end !important;
  }
  .zg-justify-content-xxl-center {
    justify-content: center !important;
  }
  .zg-justify-content-xxl-between {
    justify-content: space-between !important;
  }
  .zg-justify-content-xxl-around {
    justify-content: space-around !important;
  }
  .zg-justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }
  .zg-algin-items-xxl-start {
    align-items: flex-start !important;
  }
  .zg-algin-items-xxl-end {
    align-items: flex-end !important;
  }
  .zg-algin-items-xxl-center {
    align-items: center !important;
  }
  .zg-algin-items-xxl-baseline {
    align-items: baseline !important;
  }
  .zg-algin-items-xxl-stretch {
    align-items: stretch !important;
  }
  .zg-algin-content-xxl-start {
    align-content: flex-start !important;
  }
  .zg-algin-content-xxl-end {
    align-content: flex-end !important;
  }
  .zg-algin-content-xxl-center {
    align-content: center !important;
  }
  .zg-algin-content-xxl-between {
    align-content: space-between !important;
  }
  .zg-algin-content-xxl-around {
    align-content: space-around !important;
  }
  .zg-algin-content-xxl-stretch {
    align-content: stretch !important;
  }
  .zg-algin-self-xxl-auto {
    align-self: auto !important;
  }
  .zg-algin-self-xxl-start {
    align-self: flex-start !important;
  }
  .zg-algin-self-xxl-end {
    align-self: flex-end !important;
  }
  .zg-algin-self-xxl-center {
    align-self: center !important;
  }
  .zg-algin-self-xxl-baseline {
    align-self: baseline !important;
  }
  .zg-algin-self-xxl-stretch {
    align-self: stretch !important;
  }
}


.zg-grid-column-container {
  padding: 25px 0;
  border: 2px dashed #d5dadf;
  position: relative;

  .close-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }

  .zg-column-block-for-grid {
    text-align: center;

    .zg-flex-grid-column {
      display: flex;
      flex-wrap: wrap;
      max-width: 550px;
      justify-content: center;
      align-items: center;
      grid-gap: 5px;
      margin: 0 auto;

      svg {
        height: 45px;
        width: 80px;
        cursor: pointer;

      }

    }
  }
}

.box-block {
  width: 40px;
  height: 40px;
  background-color: brown;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  .plus {
    cursor: pointer;

    svg {
      color: white;
    }
  }
}


/************** Global styling for all pages ***********/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  color: inherit;
  text-decoration: none;
}

hr {
  border: none;
  height: 1px;
  background: #ebebeb;
  margin: 18px 0;
}

select {
  background-color: transparent;
}

select:focus-visible {
  outline: none;
}

.container {
  padding: 0 15px;
  margin: auto !important;
  max-width: 1260px;
  width: 100%;
  height: 100%;
}

.d-flex {
  display: flex;
}

.relative {
  position: relative;
}

.w-100 {
  width: 100% !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 1px solid black;
  -webkit-text-fill-color: black;
  -webkit-box-shadow: 0 0 0 1000px #fff inset;
  transition: background-color 5000s ease-in-out 0s;
}

/**********************************/

.mobile_show {
  display: none;

  @media (max-width: 992px) {
    display: block;
  }
}

.desktop_show {
  display: block;

  @media (max-width: 992px) {
    display: none;
  }
}

.__jivoMobileButton {
  margin-bottom: 60px;
}

.product-card__info_sub.cross__prods {
  display: flex;
  align-items: center;
  text-align: left;
}

.short-description,
.description-size p {
  word-break: break-word !important;
}

.style_for_dollar {
  order: -1;
}

.truncateF16 {
  max-height: 8rem;
  -webkit-box-orient: vertical;
  display: block;
  display: -webkit-box;
  overflow: hidden !important;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
}


@media (max-width: 991px) {

  .mobile-symbol,
  .lang-mobile-img {
    order: -1;
    font-size: 16px;
    font-family: "Open Sans", sans-serif;
  }
}

.SuspendMain {
  width: 100%;

  background-image: url("../svg/suspendBack.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Suspend {
  h3 {
    margin-top: 10px;
  }
}

.Suspend {
  padding-left: 15px !important;
  padding-right: 15px !important;
  position: absolute;
  transform: translate(-50%, -50%);
  left: 50%;
  top: 50%;

  > div {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  > h1 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 45px;
    line-height: 68px;
    text-align: center;
    color: #161616;
  }

  > h3,
  h5 {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: #787878;
  }

  a {
    font-family: "Poppins", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #ff5319;

    &:hover {
      color: #00254a;
    }
  }
}

@media screen and (min-width: 720px) and (max-width: 1280px) {
  .SuspendImage {
    div {
      div {
        max-width: 80%;
      }
    }
  }
}

@media (max-width: 745px) {
  .SuspendMain {
    background-image: url("../svg/suspendBackTablet.svg");
  }
}

@media screen and (min-width: 380px) and (max-width: 500px) {
  .SuspendMain {
    background-image: url("../svg/suspendBackMobile.svg");
  }

  .SuspendImage {
    max-width: 228px;
    max-height: 204px;
  }

  .Suspend {
    > h1 {
      font-size: 20px;
      line-height: 45px;
    }

    > h3,
    h5 {
      font-size: 14px;
      line-height: 21px;
    }

    a {
      font-size: 14px;
      line-height: 21px;
    }
  }
}

@media screen and (min-width: 320px) and (max-width: 380px) {
  .Suspend {
    > h1 {
      font-size: 20px;
      line-height: 45px;
    }
  }
}

.cookie_body {
  max-width: 441px !important;
  width: 100% !important;
  background-color: #ffffff !important;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2) !important;
  border-radius: 10px !important;
  bottom: 20px !important;
  left: 20px !important;
  z-index: 9999999999 !important;
  display: none;
}

.cookie_body_elements {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;

  div {
    > h3 {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 22px;
      text-transform: capitalize;
      color: #161616;
      margin-bottom: 5px;
    }

    > p {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #333333;
      margin-bottom: 0 !important;
    }
  }
}

.cookie_button_wrapper {
  width: 100% !important;
  display: flex !important;
  justify-content: flex-end !important;
}

.cookie_button {
  color: #ffffff !important;
  //background-color: $main-theme-color-dark !important;
  background: $btn-primary-bg-color !important;
  font-size: 16px !important;
  border-radius: 10px !important;
  padding: 10px 20px !important;
  border-style: none !important;

  &:hover {
    background-color: $dark-color !important; // $main-theme-color-hover
    color: $dark-opposite-color !important
  }
}

.cookie_image {
  > svg {
    > path {
      fill: $main-theme-color-dark !important;
    }
  }
}

.cookie_content {
  margin: 15px 15px 0 !important;
}

@media (max-width: 481px) {
  .cookie_body {
    left: 0 !important;
  }
}


.register-account-title {
  text-align: center;
  padding: 20px 0 16px;
  font-weight: 700;
  font-size: 30px;

  > form {
    display: flex;
    flex-direction: column;
  }
}

.pagination-container {
  display: flex;
  list-style-type: none;

  .pagination-item {
    padding: 0 12px;
    height: 32px;
    text-align: center;
    margin: auto 4px;
    color: rgba(0, 0, 0, 0.87);
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 32px;

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      cursor: pointer;
    }

    &.selected {
      background-color: rgba(0, 0, 0, 0.08);
    }

    .arrow {
      &::before {
        position: relative;
        content: "";
        display: inline-block;
        width: 0.4em;
        height: 0.4em;
        border-right: 0.12em solid rgba(0, 0, 0, 0.87);
        border-top: 0.12em solid rgba(0, 0, 0, 0.87);
      }

      &.left {
        transform: rotate(-135deg) translate(-50%);
      }

      &.right {
        transform: rotate(45deg);
      }
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
        border-right: 0.12em solid rgba(0, 0, 0, 0.43);
        border-top: 0.12em solid rgba(0, 0, 0, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: $main-theme-color-light;
}


.absolute {
  position: absolute !important;
}

.container_fm {
  max-width: 1140px;
  padding: 16px;
  margin: 0 auto;
}

.block-features__list {
  border: none;
  padding: 0 50px;
  margin-top: 50px;
}

@media screen and (min-width: 1560px) and (max-width: 1889px) {
  .block-features__list {
    padding: 0 280px;
  }
}

.activeButtnon {
  color: white;
}

.passiveButton {
  background-color: transparent;
  color: white;
}

.block-header__title__custom {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0 !important;
}

@media screen and (max-width: 991px) {
  .block-header__title__custom {
    margin: unset;
  }
}

.brands {
  padding: 50px 0;
}


.footer-links__list li {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #e4e4e4;
  margin: 2px 0;
  padding: 5px;
}

.footer_custom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer_custom_wrap {
  align-self: flex-start;
}

.footer-links__list ul li:first-child {
  justify-content: center !important;
}

.footer_day,
.footer_calc {
  justify-content: flex-start !important;
  color: #fc562e;
}

.footer_day span,
.footer_calc span {
  margin-left: 5px;
}

.block-header__title__custom {
  font-weight: 200;
  font-size: 24px;
}

@media screen and (max-width: 991px) {

  .fieldset-params-fms {
    margin-bottom: 10px;
    flex-direction: column;

    input {
      padding: 16.5px 14px;
    }
  }


  .block-header__title__custom {
    font-size: 25px;
  }

  .mobileMenuFooter-fms .social-links__item {
    position: relative;
  }
}

@media screen and (max-width: 460px) {
  .block-header__title__custom {
    font-size: 23px;

    &::after,
    &:before {
      width: 70px;
      height: 3px;
    }
  }
}

@media screen and (max-width: 382px) {
  .block-header__title__custom {

    &::after,
    &:before {
      margin: 0 5px;
      width: 60px;
      height: 3px;
    }
  }
}

@media screen and (max-width: 382px) {
  .block-header__title__custom {

    &::after,
    &:before {
      margin: 0 5px;
      width: 43px;
      height: 3px;
    }
  }
}

.btn-primary {
  border-radius: 8px;
}

.address-card__badge {
  color: #fff;
}

.view-options__control {
  max-width: 166px;
  width: 100%;
}

#view-options-limit,
#view-options-sort {
  background-position: right 1.5rem center;
  background-size: initial;
  width: 100%;
  border-radius: 21px;
  text-align-last: center;
}

.input-range__track--active {
  background: #56cfe1;
}

.input-range__slider:after,
.input-range__slider {
  background: #56cfe1;
}

.btn-secondary {
  border-radius: 8px;
}

.line {
  width: 100%;
  height: 0.2px;
  background-color: #333333;
}

.alert-danger {
  background: none;
  color: red;
  font-size: 12px;
  padding: 0;
  margin: 0 !important;
}

@media (max-width: 1199px) and (min-width: 992px) {
  .departments {
    width: 230px;
  }
}


.chek-fms {
  justify-content: center;
  align-items: center;
  padding: 6px;
  height: 50px;
  overflow: hidden;

  svg {
    height: 50px;
    min-width: 50px;
    margin-right: 16px;
  }
}


.product_attributes {
  .product__meta {
    font-size: 16px;
    flex-direction: column;
    margin-top: 30px;

    li {
      max-width: 250px;
      justify-content: space-between;
      align-items: flex-start;
      display: flex;
    }
  }
}


.btn-orange {
  color: white;
  //background: $main-theme-color-light;
  border: 0;

  &:hover {
    color: white;
    opacity: 0.7;
  }

  &:focus {
    color: white;
  }
}

.logos-container {
  height: max-content;
  max-width: 80%;
  position: relative;
  margin: 34px auto 70px;
}

.brand-logos-body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  flex-direction: row;
  height: max-content;
  max-width: 100%;
  margin-top: 61px;
}

.brand-logo-image {
  width: 105px;
  height: 68px;
  background-size: cover;
  margin: 4px;
}

@media screen and (max-width: 1500px) {
  .brand-logo-image {
    width: 100px;
    height: 60px;
  }
}

@media screen and (max-width: 1111px) {
  .brand-logos-body {
    width: 80%;
    height: max-content;
    margin: auto;
    margin-bottom: 10%;
  }

  .brand-logo-image {
    width: 120px;
    height: 70px;
  }
}

@media screen and (max-width: 854px) {
  .logos-container {
    height: max-content;
  }

  .brand-logos-body {
    height: max-content;
    width: max-content;
  }
}

.footer-link-style {
  display: block;
  color: gray;
  width: max-content;
  opacity: 1;
  margin-bottom: 12px;
  font-size: 14px;

  &:hover {
    color: gray;
  }
}

.site-footer-logo {
  text-align: center;
  padding: 0 20px;
  padding-top: 4px;
  max-width: 94px;
  max-height: 52px;
  border: 1px solid lightgray;
  font-weight: 500;
}

.shop-layout-categories {
  text-align: center;
  color: black;
  font-size: 36px;
  padding-top: 23px;
  font-style: revert;
  margin-bottom: 44px;
}

.filter-underline {
  height: 3px;
  width: 20%;
  border-bottom: 2px solid black;
  margin-top: 5px;
}

@media screen and (max-width: 991px) {

  .alert-success .modal-content {
    height: 300px !important;
  }

  .active {
    .passwordChange {
      width: 90vh !important;
    }
  }

  .site-footer {
    display: none;
  }

  .wishlist-card-image {
    min-height: 144px;
  }
}

.color-lightgray-comm-fms {
  color: #3d464d;
  font-weight: bold;
  font-size: 18px;
}

@media screen and (max-width: 768px) {

  .shopping-cart-mobile-fms {
    margin: 0 !important;
  }

  .shopping-cart-totals {
    max-width: none !important;
  }
}

@media screen and (max-width: 600px) {
  .product-card__old-price {
    display: block;
  }
}

.login-input {
  opacity: 0.6;
  padding: 20px 15px;
  font-size: 15px;

  &:focus {
    opacity: 1;
  }

  &:after {
  }
}

.position-relative {
  position: relative;
}

.mail-placeholder {
  color: #757373;
  position: absolute;
  top: 25%;
  left: 5%;
  opacity: 0.4;
}

.password-placeholder {
  color: #757373;
  position: absolute;
  top: 15%;
  left: 4%;
  opacity: 0.4;
}

.w50 {
  width: 48%;
  height: 10%;
  font-size: 15px;
}

.categories-filter-input {
  height: max-content;
  padding: 7px 5px;
  font-size: 16px;
  font-weight: 100;
}

.menu-btn-img {
  margin-right: 10px;
}


.shopping-cart-fms {
  margin-right: 1px;
  margin-top: 20px !important;
}

.wrapper-dropdown-selected-item {
  padding-bottom: 3px;
  padding-right: 10px;
}

.language-dropdown-arrow-desktop,
.language-dropdown-arrow-mobile {
  fill: #999 !important;
}

.language-dropdown-arrow-desktop {
  display: block !important;
}

.language-dropdown-arrow-mobile {
  display: none !important;
}


@media (max-width: 991px) {
  .wrapper-dropdown-selected-item {
    order: 1 !important;
  }

  .language-dropdown-arrow-desktop {
    display: none !important;
  }

  .language-dropdown-arrow-mobile {
    display: block !important;
  }
}

.currency-adjust {
  margin-left: auto;
}

.product-card-add > * {
  stroke: #ccc;
}

.product-card-add:hover > * {
  stroke: #3d464d;
}

.login-drop-btn {
  font-size: 15px;
}

.no-border {
  border: none !important;
}

.btn {
  white-space: nowrap;
  height: auto;
  text-overflow: ellipsis;
  overflow: hidden;

  &:hover {
    cursor: pointer;
  }
}

.f15 {
  font-size: 15px;
}

.savings_fms {
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.savings_fms h4 {
  margin: 0;
}

.footer-contacts {
  justify-content: center;
  flex-direction: column;
}

.social-link-color {
  color: $footer-social-link-color;
  border-color: $footer-social-link-color !important;
}

.container-offHome {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media screen and (min-width: 992px) {
  .container-offHome {
    max-width: 960px;
  }
}

@media screen and (min-width: 1200px) {
  .container-offHome {
    max-width: 1140px;
  }
}

@media screen and (min-width: 300px) and (max-width: 991px) {

  .input-titles-track {
    display: flex;
    width: 90% !important;
    flex-direction: column !important;
  }

  .container-offHome {
    max-width: 100%;
  }

}

.inner-Description-down,
.inner-Description-up {
  margin: 13px auto 0;
}

.f16 {
  font-size: 16px;
}

.checkout-comment {
  height: 111px !important;
}

.login-container {
  margin-bottom: 190px;
  display: flex;
  justify-content: center;

  .row {
    width: 50%;
  }

}

.forgot-pass-input {
  padding: 10px;
  font-size: 18px;
  font-weight: 500;
}

.forgot-pass-block {
  width: 50%;
  margin-bottom: 95px;

  .btn-wrapper {
    margin-top: 40px;
  }

}

.reset-pass {
  margin-top: 80px;
}

@media screen and (max-width: 767px) {


  .mobile-checkout-style {

    .c-pl-2,
    .c-pl-2 {
      padding: 0 !important;
    }
  }

  //.upsell_body {
  //  .product__info {
  //    padding: 0 !important;
  //  }
  //
  //  .product__gallery {
  //    padding: 0;
  //    margin: 0;
  //  }
  //
  //  .product__actions {
  //    padding-left: 0;
  //  }
  //}
}

.modal-body {
  span {
    position: relative !important;
  }

  img {
    position: unset !important;
    height: auto !important;
    width: auto !important;
    max-height: unset !important;
    max-width: unset !important;
    min-height: unset !important;
    min-width: unset !important;
  }
}

.shopping-cart-totals {
  max-width: 370px;
  width: 100%;
}

@media screen and (max-width: 767px) {
  .shopping-cart-totals {
    max-width: 250px;
  }

  .passwordChange {
    .modal-body {
      padding: 0 15px 50px !important;
    }
  }
}

@media screen and (max-width: 447px) {
  .shopping-cart-totals {
    max-width: 200px;
  }
}

@media screen and (min-width: 768px) and (max-width: 928px) {

  .products-list__body {
    .products-list__item {
      margin-right: auto;
    }

    .products-list__item:nth-child(3) {
      margin-right: unset;
    }
  }
}

.header-logo-img {
  height: 45px;
  max-width: 150px;
}

.checkout-comment-margin {
  margin-top: 25px;
  margin-bottom: 20px;
  display: block;
}

.checkout-select-billing {
  max-width: 100%;
  padding: 5px;
}

.suggestion-img {
  height: inherit;
}

@media screen and (max-width: 767px) {
  .password-responsive {
    font-size: 15px;
  }
}

.wishlist-card-parent {
  width: 25%;
  margin: 20px 0;
  padding: 10px;
}

.wishlist-card {
  width: 100%;
}

@media screen and (max-width: 991px) {
  .wishlist-card-parent {
    width: 33%;
  }
}

@media screen and (max-width: 767px) {
  .wishlist-card-parent {
    width: 50%;
  }
}

@media screen and (max-width: 400px) {
  .wishlist-card-parent {
    padding: 5px;
  }
}

.coupon-content-block-title {
  font-size: 18px;
  font-weight: 800;
}

@media screen and (max-width: 767px) {
  .password-responsive {
    font-size: 15px;
  }
}

.blog-page-title {
  text-align: center;
}

.cupon-input-fms {
  height: 39px;
}

.continue-shopping-arrow {
  display: inline-block;
  margin: 0 6px;
}

@media screen and (min-width: 768px) {
  .continue-shopping-arrow {
    display: none;
  }
}

.border-input-light {
  border: 1px solid #e6e6e6;
}

.form-select {
  border: 1px solid #aaa;
  border-radius: 5px;

  &:focus-visible {
    outline: none !important;
  }
}

.input-design {
  height: 35px;

  &.button:hover {
    cursor: pointer;
  }
}

@media screen and (max-width: 991px) {
  .product-card {
    position: unset !important;
    z-index: unset !important;
  }

  .mobile-footer-navs-fms {
    bottom: 0;
    position: fixed;
    background-color: white;
    width: 100%;
    border-top: 1px solid silver;
  }
}

thead .cart-table__column--total {
  text-align: center !important;
}

.update-cart-button-fms {
  border-radius: 3px !important;
  border: 1px solid silver !important;
  color: #000 !important;
  max-width: 190px;
  width: 100%;
  min-height: 40px;
  font-size: 16px;
}

@media screen and (max-width: 420px) {
  .update-cart-button-fms {
    max-width: 135px;
  }
}

.continue--arrow {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.checkout-log-in-fms {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.card-title-fms {
  margin-top: 20px;
  margin-bottom: 30px !important;
}

.mobile-social-links {
  li {
    position: relative;
  }
}

@media screen and (max-width: 991px) {

  .btn-wrapper {
    width: 100%;

    button {
      width: 100%;
      margin-bottom: 15px
    }
  }
}

.btn-primary-fms {
  border-radius: 3px !important;
}

.checkout-input-fms {
  min-height: 40px !important;
}

.block-sidebar__body {
  margin-top: 10px;
}

.department-title-arrow-fms {
  display: flex;
  align-items: center;
}

.department-title-fms {
  padding-right: 20px;
}

.active {
  .register {
    width: unset !important;
  }
}

.product-page-fms {
  height: 260px;
}

@media (max-width: breakpoint(xs-end)) {

  .site__footer {
    margin-top: 20px;
  }

  .wishlist-card {
    min-width: 100%;
  }

  .page-header {
    margin-top: 0;
    margin-bottom: 47px;
  }

  .wishlist-title-fm {
    margin-bottom: 0;
  }
}


@media screen and (max-width: 767px) {

  .error-title-fms {
    padding-top: 5%;
    position: absolute;
    right: 0;
  }

  .wishlist-title-fm {
    text-align: center;
  }

  .checkout-card-titles-wrapper {
    display: flex;
  }
}

@media screen and (max-width: 768px) {

  .contact-customhtml-fms {
    font-size: 25px;
    font-weight: 400;
    margin-bottom: 30px;
  }


  .contact-with-us {
    .inner-addToCart {
      width: 100%;
    }
  }
}

.contact-with-us {
  padding: 1.5rem !important;
}

@media screen and (max-width: 425px) {
  .font-for-cart-responsive-parent > * {
    font-size: 14px;
  }
}

.wishlist-toast {
  font-weight: 400;
  color: black;
  font-size: 16px;
  background: white;
}

.w90 {
  width: 90%;
}

.found-product-card {
  padding: 5px;
}


.text-danger {
  font-size: 12px;
  color: red;
}

.input-titles-track {
  display: flex;
  width: 45%;
  flex-direction: column;
}

.error-page-container {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;

  .error-page-container_section {
    width: 60%;
    text-align: center;

    h3 {
      font-size: 23px !important;
    }

    p {
      span {
        cursor: pointer;
      }
    }
  }
}

///////////// FIXME: in future transfer this part to shared.module.scss or in scss file for product gallery


.carousel-parent {

  .slick-track {
    margin-left: 0 !important;
  }
}

.prod {
  width: 730px;
  height: 331px;
  background-color: burlywood;
  margin: 50px auto 80px;
}

.alert-success {
  z-index: 9999;

  .modalss.active {
    left: 0;
  }

  .modal-content {
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn-secondary {
    width: 250px;
    margin: 30px auto;
    font-size: 22px;
  }
}

.bg_className {
  background: #f5f5f5;
}

.btn:focus {
  box-shadow: none;
}

.invalid-fields {
  cursor: no-drop !important;
}

.btn:disabled {
  cursor: no-drop !important;
}

.mobile-checkout-style {
  .MuiFormControl-root {
    width: 100%;
  }
}

.billing-fms {
  .MuiFormControl-root {
    width: 100%;
  }

  .checkout-select-billing {
    height: 54px;
    border-radius: 4px;
    padding-right: 10px;
    border: 1px solid #aaa;
    background-color: white;
  }
}

.already-have-account-mail-fms {
  .MuiTextField-root {
    width: 100%;
    padding-right: 15px;
  }
}

/********************************
        upsell/crosssel
*******************************/


.crossSell-second {
  margin-top: 40px;
}

//@media (max-width: 576px) {
//  .upsell_body {
//    .product__content {
//      .product__sidebar {
//        display: flex;
//      }
//    }
//  }
//}
//
//@media (max-width: 500px) {
//  .upsell_body {
//    .product__content {
//      .product__info {
//        .short_description_block {
//          display: block !important;
//        }
//      }
//    }
//  }
//}
//
//@media (max-width: 426px) {
//  .upsell_body {
//    .product__content {
//      .product__gallery {
//        .slick-list {
//          max-height: 300px;
//        }
//      }
//    }
//  }
//}

.product__actions-item.product__actions-item--addToCart.upsell {
  margin: 0 !important;
  height: 42px !important;
  max-width: 150px;
}

//.upsell_body {
//  .product-image.product-image--location--gallery {
//    padding: 0 !important;
//  }
//
//  .product-image__img {
//    padding: 0 !important;
//  }
//
//  .btn.btn-orange.rounded-pills {
//    height: 100% !important;
//  }
//
//  .product__actions {
//    margin: 0 !important;
//    gap: 20px !important;
//  }
//
//  .product__name {
//    font-size: 22px !important;
//  }
//
//  .product-inner-description-title {
//    font-weight: 600 !important;
//  }
//}

.product-for--one-element {
  width: 100% !important;
}

//@media (max-width: 635px) {
//  .cross-sell-button.cross__prods {
//    flex-direction: column;
//    gap: 16px;
//  }
//
//  .cross-sell-button.cross__prods {
//    .product-inner-quantity {
//      width: 100%;
//    }
//  }
//
//  .cross_btn.cross__prods {
//    width: 100%;
//  }
//}

@media (max-width: 500px) {
  //
  //.upsell_body {
  //  .product__gallery {
  //    margin: 0 !important;
  //  }
  //}

  //.upsell_body {
  //  display: block;
  //  margin: 0;
  //
  //  .product__actions-item--addToCart {
  //    margin: 4px auto !important;
  //  }
  //}

  .product-card__symbol {
    margin-left: 0;
  }

  .product-card__info {

    .product-card__info_sub {
      display: block !important;
    }
  }

  .product__quantity {
    width: 100% !important;
  }

  .product-card__prices {
    margin: 10px 0;
    min-height: 0 !important;
  }
}

@media (max-width: 430px) {
  .cross__prods {
    .product__actions-item {
      padding: 0;
    }
  }
}

.pay-button {
  padding: 0.7rem 2rem;
  width: 100%;
  margin: 1rem 0;
  color: white;
  font-weight: bold;
  font-size: medium;
  background-color: #556cd6;
  border: 0;
  border-radius: 5px;
  box-shadow: 0 4px 5.5px 0 rgba(0, 0, 0, 0.07);
  transition: box-shadow 500ms;
  cursor: pointer;
}

.pay-button:disabled {
  background-color: #afafaf;
  box-shadow: none;
  cursor: default;
}

.pay-button:disabled:hover {
  box-shadow: none;
}

.pay-button:hover {
  box-shadow: 2px 5px 15px 2px rgba(0, 0, 0, 0.2);
}


.notif_block {
  width: 25%;
  position: fixed;
  z-index: 999999999999;
  left: 50%;
  transform: translate(-50%, -50%);
  top: 11%;
  background: #FFFFFF;
  box-shadow: 0 5px 20px 0 #00000033;
  border-radius: 10px;
  padding: 20px;
}

.notif_content {
  h1 {
    font-size: 16px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0;
    text-align: left;
    color: #161616;
  }

  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    text-align: left;
    color: #333333;
  }
}

.notif_actions {
  display: flex;
  justify-content: flex-end;

  button:nth-child(1) {
    border: none;
    background: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0;
    text-align: left;
    margin-right: 20px;
    color: $main-theme-color-light;
  }

  button:nth-child(2) {
    background: $main-theme-color-light;
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    margin-right: 20px;
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
    color: #fff;
    border: none;
  }

}


@media screen and (max-width: 500px) {
  .notif_block {
    width: 92%;
    top: 50%;
  }

  .notif_content {
    p {
      font-size: 14px;
    }
  }
}

@media (min-width: 501px) and (max-width: 800px) {
  .notif_block {
    width: 70%;
  }

}

/////BUILDER CLASSSS

.columnContainer,
.columns {
  display: flex;
  padding: 20px 0;
}

.column {
  img {
    width: 100%
  }
}


@media (max-width: 991px) {
  .columns {
    flex-wrap: wrap;
  }
  .column {
    img {
      width: 100% !important;
      height: unset !important;
    }

    div, p, span {
      margin: unset !important;
      padding: unset !important;
    }
  }
}

.images--block {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .images--block_image {
    width: 50%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .images--block_text {
    width: 47%;
    height: 100%;
    margin-top: 20px;
  }
}

.images--block2 {
  margin: 70px auto 70px auto;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  justify-content: space-between;

  .images--block_image2 {
    width: 47%;
    height: 100%;

    img {
      width: 100%;
      height: 100%;

    }
  }

  .images--block_text2 {
    width: 50%;
    height: 100%;
    margin-top: 20px;
  }
}


@media screen and (max-width: 1100px) {
  .images--block {
    flex-direction: column;
    align-items: center;

    .images--block_image {
      width: 96%;
    }

    .images--block_text {
      width: 96%;
    }
  }

  .images--block2 {
    flex-direction: column;
    align-items: center;

    .images--block_image2 {
      width: 96%;
    }

    .images--block_text2 {
      width: 96%;
    }
  }
}

@media screen and (max-width: 700px) {
  .images--block {
    width: 100%;
    flex-direction: column;
    align-items: center;

    .images--block_image {
      width: 90%;
    }

    .images--block_text {
      width: 90%;
    }
  }

  .images--block2 {
    width: 100%;
    flex-direction: column;
    align-items: center;

    .images--block_image2 {
      width: 90%;
    }

    .images--block_text2 {
      width: 90%;
    }
  }
}

/* Slick slider  styling */

/* Icons */
@font-face {
  font-family: 'slick';
  font-weight: normal;
  font-style: normal;

}

/* Arrows */
.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;

  position: absolute;
  top: 50%;

  display: block;

  width: 20px;
  height: 20px;
  padding: 0;
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);

  cursor: pointer;

  color: transparent;
  border: none;
  outline: none;
  background: transparent;
}

.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: .25;
}

.slick-prev:before,
.slick-next:before {
  font-size: 20px;
  line-height: 1;

  opacity: .75;
  color: white;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir='rtl'] .slick-prev {
  right: -25px;
  left: auto;
}

.slick-prev:before {
  content: '←';
}

[dir='rtl'] .slick-prev:before {
  content: '→';
}

.slick-next {
  right: -25px;
}

[dir='rtl'] .slick-next {
  right: auto;
  left: -25px;
}

.slick-next:before {
  content: '→';
}

[dir='rtl'] .slick-next:before {
  content: '←';
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;

  display: block;

  width: 100%;
  padding: 0;
  margin: 0;

  list-style: none;

  text-align: center;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  font-size: 0;
  line-height: 0;

  display: block;

  width: 20px;
  height: 20px;
  padding: 5px;

  cursor: pointer;

  color: transparent;
  border: 0;
  outline: none;
  background: transparent;
}

.slick-dots li button:hover,
.slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before,
.slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  font-size: 6px;
  line-height: 20px;

  position: absolute;
  top: 0;
  left: 0;

  width: 20px;
  height: 20px;

  content: '';
  text-align: center;

  opacity: .25;
  color: black;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  opacity: .75;
  color: black;
}

.product__quantity {
  .cart-quantity-responsive {
    height: 34px;
  }
}

.popUp-svg {
  display: flex;
  justify-content: center;
}

.faild-toast-fms {
  display: flex;
  justify-content: center;
  align-items: center;
}

.faild-toast-fms,
.chek-fms {
  justify-content: center;
  align-items: center;
  padding: 6px;
  height: 50px;
  overflow: hidden;

  svg {
    height: 50px;
    min-width: 50px;
    margin-right: 16px;
  }
}

