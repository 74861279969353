@import '../variables';


.order-header__subtitle {
  color: $card-font-alt-color;
  font-size: 14px;
  line-height: 1.375;
  clear: both;
  padding-top: 12px;

  mark {
    padding: 0;
    color: $card-font-color;
    background: transparent;
    font-weight: $font-weight-medium;
  }
}

