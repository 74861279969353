@import '../variables';
@import '../functions';
@import '../mixins/direction';

.block-sidebar--position--start {
  @include direction {
    #{$padding-inline-end}: 20px;
  }

  @media (max-width: breakpoint(lg-end)) {
    @include direction {
      #{$padding-inline-end}: 6px;
    }
  }

  @media (max-width: breakpoint(md-end)) {
    @include direction {
      #{$padding-inline-end}: 0;
    }
  }
}

.block-sidebar__close {
  border: none;
  background: transparent;
  height: $mobilemenu-header-height;
  width: $mobilemenu-header-height + 1px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  fill: $mobilemenu-close-button-default-color;
  transition: fill .2s;

  @include direction {
    #{$border-inline-start}: 1px solid $mobilemenu-divider-color;
  }

  &:focus {
    outline: none;
  }

  &:focus,
  &:hover {
    fill: $mobilemenu-close-button-hover-color;
  }
}