@import "../variables";
@import "../functions";
@import "../mixins/direction";

@mixin local-offcanvas() {
  border: none;
  padding-top: 0;

  .widget-filters__title {
    display: none;
  }
  .widget-filters__item:first-child {
    border-top: none;
  }
  .widget-filters__item {
    border-width: 1px;
  }
}

.widget-filters__item {
  padding: 8px $widget-shop-padding;
  margin: 0 (-$widget-shop-padding);
  border-bottom: 2px solid $card-border-color;

  &:first-child {
    border-top: 2px solid $card-border-color;
  }
}