@import "../variables";
@import "../functions";
@import "../mixins/product-card";
@import "../mixins/preloader";

.block-products-carousel[data-layout^="grid-"] {
  .product-card {
    @include product-card-grid();
  }
}

.block-products-carousel[data-layout="grid-4"] {
  .product-card {
    @include product-card-grid-nl();
  }

  @media (min-width: 480px) and (max-width: breakpoint(lg-end)) {
    .product-card {
      @include product-card-grid-sm();
    }
  }
}

.block-products-carousel[data-layout="grid-4-sm"] {
  @media (min-width: 480px) {
    .product-card {
      @include product-card-grid-sm();
    }
  }
  @media (max-width: 479px) {
    .product-card {
      @include product-card-grid-nl();
    }
  }
}

.block-products-carousel[data-layout="grid-5"] {
  @media (min-width: 480px) {
    .product-card {
      @include product-card-grid-sm();
    }
  }
  @media (max-width: 479px) {
    .product-card {
      @include product-card-grid-nl();
    }
  }
}

.block-products-carousel[data-layout="horizontal"] {
  .product-card {
    @include product-card-horizontal();
  }
}
