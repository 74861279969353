.site{
  background: #F8F8F9;
}

.slick-initialized {
  height: 100%;

  .slick-list {
    height: 100%;

    .slick-track {
      height: 100%;
    }
  }
}

.mobile-header-dropdown {
  .header_topBar-dropdown__IYxzl {
    min-height: unset !important;
    display: flex;
    width: 40px;
    justify-content: center;
    padding: 0 5px;

    img{
      border: 1px solid #DDD !important;
      width: 30px !important;
      height: 24px !important;
    }

    .header_topBar-dropdown__btn__nO6mK {
      padding: 0;

      .header_lang-name-image__2IRAr {
        padding: 0;

        .header_lang-name__nSyx6 {
          display: none;
        }
      }

      svg {
        display: none;
      }
    }

    .header_topBar-dropdown__body__bwbAN {
      position: absolute !important;
      right: 0;
      width: 100%;
      padding: 0;

      .header_menu--with-icons__cETwb {
        margin: 0;
        width: unset;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 5px;
        padding: 5px;
        box-shadow: unset;

        a{
          padding: 0;

          .header_menu__icon__11P_D {
            margin: 0;
            position: static;

            span{
              display: block !important;
            }
          }

          .header_dropdown-submenu-items__0JiBl {
            display: none;
          }
        }

      }
    }
  }
}

.background_layer{
  height: 100vh;
  position: fixed;
  width: 100%;
  background: rgba(0, 0, 0, .3);
  z-index: 50;
  bottom: 0;
  overflow-y: scroll;
}

@media screen and (max-width: 767px) {

  .shop_cart-table__column--quantity__cZuMs {
    .shared_input-number__mS_oj {
      width: 110px;
      display: inline-block;
      margin-left: 21px;
    }

    .shared_cart-quantity-responsive__5_4kx {
      border: 1px solid #e3e3e3;
      box-shadow: none;
      border-radius: 5px;
    }
  }
}