@import "../variables";
@import "../functions";
@import "../mixins/direction";

.site-header__logo {
  width: max-content;
  height: 55px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.site-header__logo div {
  width: 95px;
  height: 35px;
  position: relative !important;
}

.site-header__phone {
  line-height: 1;
  flex-shrink: 0;

  @include direction {
    text-align: $inline-end;
    #{$margin-inline-start}: 80px;
  }
}

.site-header__phone-title {
  font-size: 14px;
  color: $header-font-muted-color;
  margin-bottom: 6px;
}

.site-header__phone-number {
  font-size: 20px;
  font-weight: $font-weight-bold;
}

@media (min-width: breakpoint(lg-start)) and (max-width: breakpoint(lg-end)) {
  .site-header__logo {
    justify-content: flex-start;

    @include direction {
      #{$margin-inline-end}: 24px;
    }
  }
}
