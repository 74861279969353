.order-details-info-width {
  max-width: 25%;
  padding-right: 60px;
  text-align: center;

  &:first-child {
    padding: 0;
  }
}

.order-details-title-width {
  width: 25%;
}

.mobile-orders-table {
  & tr {
    margin-bottom: 10px !important;
  }
}


.f14 {
  font-size: 14px;
}
