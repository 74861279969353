@import '../variables';
@import '../functions';
@import '../mixins/direction';


.addresses-list__plus {
  width: 60px;
  height: 60px;
  border: 2px dashed #ebebeb;
  position: relative;
  border-radius: 30px;
  margin-bottom: 24px;
  transition: border-color .2s;

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 32px;
    height: 4px;
    background-color: #e5e5e5;
    border-radius: 1.5px;
    top: calc(50% - 2px);
    transition: background-color .2s;

    @include direction {
      #{$inset-inline-start}: calc(50% - 16px);
    }
  }

  &::after {
    transform: rotateZ(90deg);
  }
}

