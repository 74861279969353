@import '../variables';
@import '../functions';
@import '../mixins/direction';


.document__header {
  text-align: center;
  margin-bottom: 80px;
}

.document__subtitle {
  margin-top: 12px;
  font-size: 14px;
  line-height: 22px;
  color: $body-font-muted-color;
}


@media (max-width: breakpoint(sm-end)) {

  .document__header {
    margin-bottom: 30px;
  }

  .document__title {
    font-size: 30px;
    margin-bottom: 0;
  }

  .document__subtitle {
    margin-top: 6px;
  }
}

@media (max-width: breakpoint(xs-end)) {

  .document__header {
    @include direction {
      text-align: $inline-start;
    }
  }
}