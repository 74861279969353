@import '../variables';


.widget-tags {
  border-radius: 4px;

  padding: $widget-blog-padding;

  @media (max-width: 479px) {
    padding: 20px;
  }
}
