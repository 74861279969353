@import "../variables";
@import "../functions";
@import "../mixins/product-card";
@import "../mixins/direction";

$local-product-margin: 5px;

.block-small-products {
  min-height: 380px;
}

.block-products__featured-item {
  display: flex;
  flex-grow: 1;

  .product-card {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
  }
}

@media (min-width: breakpoint(lg-start)) {
  .block-products__featured-item {
    .product-card {
      @include product-card-grid();
      @include product-card-grid-lg();
    }
  }
  .block-products__featured + .block-products__list {
    @include direction {
      #{$margin-inline-start}: $local-product-margin;
    }
  }
  .block-products__list + .block-products__featured {
    @include direction {
      #{$margin-inline-start}: $local-product-margin * 2;
    }
  }
}

@media (min-width: breakpoint(sm-start)) and (max-width: breakpoint(md-end)) {
  .block-products__featured-item {
    display: block;

    .product-card {
      @include product-card-horizontal();
      @include product-card-horizontal-lg();
    }
  }
}

@media (max-width: breakpoint(xs-end)) {
  .block-products__featured-item {
    .product-card {
      @include product-card-grid();
      @include product-card-grid-nl();
    }
  }
}