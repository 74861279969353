.fake-svg-icon {
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: none;
  vertical-align: top;
}

.fake-svg-icon--wishlist-16 {
  width: 16px;
  height: 16px;
  font-size: 16px;

  &::before {
    content: "\e904";
  }
}

.fake-svg-icon--compare-16 {
  width: 16px;
  height: 16px;
  font-size: 16px;

  &::before {
    content: "\e901";
  }
}