@import '../functions';


.block-brands__slider {
  border-radius: 4px;

  .slick-slide > div > div {
    vertical-align: middle;

    &:focus {
      outline: none;
    }
  }
}

.block-brands__item {
  padding: 24px 24px;

  img {
    max-width: 100%;
    height: auto;
  }
}

@media (max-width: breakpoint(sm-end)) {
  .block-brands__item {
    padding: 20px 24px;
  }
}
