@import '../variables';
@import '../functions';
@import '../mixins/direction';


.block-features__list {
  border-radius: 4px;
  display: flex;
}

.block-features__item {
  flex-basis: 0;
  flex-grow: 1;
  display: flex;
}

@media screen and (max-width: 1500px) {
  .block-features__item {
    justify-content: flex-start;
  }
}

@media screen and (min-width: 1501px) and (max-width: 1889px) {
  .block-features__item {
    justify-content: center;
  }
}

.block-features__divider {
  flex-shrink: 0;
}

.block-features__icon {
  flex-shrink: 0;
  width: 48px;
  height: 48px;
  fill: $block-features-icon-color;

  @include direction {
    #{$margin-inline-end}: 20px;
  }

  svg {
    display: block;
  }
}

.block-features__content {
  padding-top: 2px;
  padding-right: 40px;
}

.block-features__title {
  font-size: 18px;
  margin-bottom: 8px;
  line-height: 24px;
  font-weight: $font-weight-bold;
}

.block-features__subtitle {
  font-size: 17px;
}


.block-features--layout--boxed {
  $local-margin: 14px;

  margin-top: -($block-margin-bottom - $local-margin);

  .block-features__list {
    border: none;
  }

  .block-features__item {
    flex-direction: column;
    background: $block-features-boxed-bg;
    border-radius: 3px;
    text-align: center;
    align-items: center;
    padding: 30px 28px 28px;
  }

  .block-features__divider {
    width: $local-margin;
  }

  .block-features__icon {
    margin-bottom: 12px;

    @include direction {
      #{$margin-inline-end}: 0;
    }
  }


  @media (min-width: 480px) and (max-width: breakpoint(md-end)) {
    .block-features__list {
      flex-wrap: wrap;
    }
    .block-features__item {
      width: 50%;
    }
    .block-features__divider:nth-child(4n) {
      width: 100%;
      height: $local-margin;
    }
  }

  @media (max-width: 479px) {
    .block-features__list {
      flex-wrap: wrap;
    }
    .block-features__item {
      width: 100%;
      color: green;
    }
    .block-features__divider {
      width: 100%;
      height: $local-margin;
    }
  }
}


.block-features--layout--classic {

  .block-features__divider {
    flex-shrink: 0;
    width: 2px;
    background: $card-border-color;
  }

  @media (max-width: breakpoint(lg-end)) and (min-width: breakpoint(lg-start)), (max-width: breakpoint(sm-end)) and (min-width: breakpoint(sm-start)) {
    .block-features__item {
      padding: 12px;
    }
    .block-features__icon {
      @include direction {
        #{$margin-inline-end}: 16px;
      }
    }
    .block-features__content {
      padding-top: 0;
    }
  }

  @media (max-width: breakpoint(md-end)) and (min-width: 420px) {
    .block-features__list {
      flex-wrap: wrap;
    }
    .block-features__item {
      flex-basis: auto;
      width: calc(50% - 1px);
      flex-shrink: 0;
    }
    .block-features__divider:nth-child(4n) {
      width: 100%;
      height: 2px;
    }
  }
  @media screen and (max-width: 576px) {
    .block-features__list {
      flex-direction: column;
      align-items: center;
    }
    .block-features__item {
      width: 100%;
      margin: 5px 0;
      display: flex;
      align-items: flex-start;
    }
  }

  @media (max-width: 419px) {
    .block-features__list {
      flex-wrap: wrap;
    }
    .block-features__item {
      flex-basis: auto;
      width: 100%;
      flex-shrink: 0;
    }
    .block-features__icon {
      @include direction {
        #{$margin-inline-end}: 24px;
      }
    }
    .block-features__divider {
      width: 100%;
      height: 2px;
    }
  }
}
