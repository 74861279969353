@import '../variables';
@import '../mixins/direction';

.set_new_address {
  display: grid;
  grid-gap: 15px;
  margin-top: 20px;
}

.checkout__totals-header {
  tr:last-child > * {
    padding-bottom: 10px;
  }
}

.checkout__totals-subtotals {
  tr:first-child > * {
    padding-top: 20px;
  }

  tr:last-child > * {
    padding-bottom: 20px;
    border-bottom: 1px solid $card-divider-color;
  }
}

.checkout__totals-footer {
  font-size: 24px;

  tr:first-child > * {
    padding-top: 10px;
  }
}

.coupone-code__list {
  margin-top: 20px;
}

.custom-style-for-long-adresses {
  overflow: hidden;
  white-space: nowrap;
  width: calc(100% - 40px);
  text-overflow: ellipsis;
}

.details-section {
  width: 100%;
}

.spinner-border {
  margin-left: 10px;
  width: 1.5rem;
  height: 1.5rem;
}

.payment-methods__item-description {
  font-size: 15px;
  line-height: 20px;
  padding: 2px 16px 13px 16px;
}

.checkout-select.checkout-input:not(select) {
  height: 45px;
  border-radius: 4px;
  border: 1px solid #aaa;
  background-color: white;
}

.c-pl-2 {
  padding-left: 8px;
}

.c-pr-2 {
  padding-right: 8px;
}

@media screen and (max-width: 768px) {
  .c-pl-2 {
    padding-left: 15px !important;
  }
  .c-pr-2 {
    padding-right: 15px !important;
  }
}


.add_grid_params {
  grid-gap: 15px;
}