@import '../variables';
@import '../functions';
@import '../mixins/direction';

.nav-panel__nav-links {
  @include direction {
    #{$margin-inline-end}: 18px;
  }
}

@media (min-width: breakpoint(lg-start)) and (max-width: breakpoint(lg-end)) {
  .nav-panel__nav-links {
    @include direction {
      #{$margin-inline-start}: 14px;
      #{$margin-inline-end}: 14px;
    }
  }
}
