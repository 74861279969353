@import '../variables';
@import '../functions';
@import '../mixins/direction';

.view-options__legend {
  @include direction {
    #{$margin-inline-start}: 20px;
  }
}

.view-options__divider {
  flex-grow: 1;
}

.view-options__filters-button {
  @include direction {
    #{$margin-inline-end}: 12px;
  }
}

.view-options__control {
  display: flex;
  align-items: center;

  label {
    margin-bottom: 0;

    @include direction {
      #{$margin-inline-end}: 8px;
    }
  }
}


@media screen and (max-width: 480px) {
  .view-options__legend {
    @include direction {
      #{$margin-inline-start}: auto;
    }
  }
  .view-options__divider {
    width: 100%;
    height: 12px;
  }
  .view-options__control {
    @include direction {
      #{$margin-inline-start}: auto;
    }

    & + & {
      @include direction {
        #{$margin-inline-start}: 24px;
      }
    }
  }
}


@media (max-width: 474px) {
  .view-options__legend {
    width: 100%;
    padding-top: 12px;
  }
  .view-options__divider {
    height: 8px;
  }
  .view-options__control {
    display: block;

    @include direction {
      float: right;
    }

    & + & {
      @include direction {
        #{$margin-inline-start}: 0;
      }
    }
  }
}
