@import "../variables";
@import "../functions";
@import "../mixins/direction";


.mobile-header__logo {
  margin: 0;
  color: inherit;
  fill: currentColor;
  @include direction {
    #{$margin-inline-end}: 16px;
    #{$margin-inline-start}: 12px;
  }

  &:hover {
    color: inherit;
  }

  svg {
    display: block;
  }
}
