@import "../variables";
@import "../mixins/direction";

.topBar__item-value {
  color: $topbar-dropdown-value-color;
  transition: all 0.1s;
}

.topBar__spring {
  flex-grow: 1;
}

.topBar__item--link + .topBar__item--link {
  @include direction {
    #{$margin-inline-start}: 16px;
  }
}

.topBar__item--link {
  padding: 0 0 0 10px;
}
