@import '../variables';

.widget-categories {
  border-radius: 4px;

  padding: 0 5px;

  @media (max-width: 479px) {
    padding: 20px 12px;
    margin-right: 0 !important;
  }
}

.widget-categories--location--shop {
  padding: $widget-shop-padding;
}
