@import "../variables";
@import "../mixins/hacks";
@import "../mixins/direction";

.dropcart__empty {
  padding: 42px 30px;
  text-align: center;
  min-height: max-content;
  max-height: 290px;
  overflow-y: auto;
}

.dropcart__product {
  display: flex;

  & + & {
    margin-top: 24px;
  }
}

.dropcart__product-name {
  text-align: left;
  margin-top: -2px;
  line-height: 18px;
  font-size: 15px;

  a {
    color: inherit;
    transition: 0.15s;
  }

  a:hover {
    color: $link-hover-color;
  }
}

.dropcart__product-info {
  margin: 0;
  flex-grow: 1;

  @include direction {
    #{$padding-inline-end}: 10px;
    #{$padding-inline-start}: 16px;
  }
}

.dropcart__product-options {
  list-style: none;
  margin: 3px 0 0;
  padding: 0;
  font-size: 13px;
  line-height: 16px;
  color: $dropcart-font-muted-color;
}

.dropcart__product-meta {
  margin-top: 5px;
  font-size: 13px;
  display: flex;
  align-items: center;
  gap: 5px;

  .product-card__prices {
    min-height: 0;
    margin: 0;
    font-weight: normal;
  }
}

.dropcart__product-price {
  font-size: 14px;
  font-weight: $font-weight-bold;
}

.dropcart__product-price {
  .shared_product-card__old-price__qVzw5 {
    display: none;
  }

  .shared_product-card__new-price__Uh_wK {
    color: black;
  }
}