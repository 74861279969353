@import "../variables";
@import "../mixins/hacks";
@import "../mixins/direction";

.account-menu__form-forgot {
  position: relative;

  a {
    margin-left: 125px !important;
  }
}

@media screen and (max-width: 991px) {
  .media-class-for-height {
    margin-bottom: 10px;
  }
}

.account-menu__user {
  display: flex;
  align-items: center;
  padding: 14px 20px;
  color: inherit;

  &:hover {
    color: inherit;
    background: $account-menu-item-hover-bg;
  }
}

.account-menu__user-avatar {
  width: 44px;
  flex-shrink: 0;

  @include direction {
    #{$margin-inline-end}: 14px;
  }

  img {
    border-radius: 100%;
    max-width: 100%;
  }
}

.account-menu__user-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.account-menu__user-name {
  font-size: 15px;
  line-height: 20px;
  font-weight: $font-weight-medium;
}

.account-menu__user-email {
  font-size: 14px;
  line-height: 18px;
  color: $account-menu-font-muted-color;
  margin-top: 1px;
}

.account-menu__links {
  list-style: none;
  padding: 12px 0;
  margin: 0;

  a {
    display: block;
    color: inherit;
    font-size: 15px;
    padding: 5px 20px;
    font-weight: $font-weight-medium;
  }

  a:hover {
    background: $account-menu-item-hover-bg;
  }
}
