@import '../variables';
@import '../functions';
@import '../mixins/direction';



.address-card__badge {
  position: absolute;
  background-color: $main-theme-color-dark;
  color: $accent-opposite-color;
  font-size: 11px;
  font-weight: $font-weight-medium;
  text-transform: uppercase;
  padding: 4px 8px 2px;
  top: -$card-border-width;

  @include direction {
    #{$inset-inline-end}: .75rem;
    #{$border-end-start-radius}: 2px;
    #{$border-end-end-radius}: 2px;
  }
}

.address-card__badge--muted {
  background-color: #f0f0f0;
  color: $light-opposite-color;
}



