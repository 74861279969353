@import '../variables';


.post-header__categories {
  display: flex;

  a {
    padding: 5px 7px 4px;
    display: block;
    font-size: 11px;
    line-height: 1;
    font-weight: $font-weight-medium;
    text-transform: uppercase;
    color: $post-categories-font-color;
    background: $post-categories-bg;
    border-radius: $post-categories-border-radius;
  }
}


