@import '../variables';


.topBar-link {
  color: $topbar-link-color;
  transition: color .3s;

  &:hover {
    color: $topbar-link-hover-color;
  }
}
