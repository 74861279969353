@import '../variables';
@import '../functions';
@import '../mixins/direction';


$item-padding: 14px;


.nav-links {
  height: 100%;
}


@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.nav-links__megaMenu {
  @include direction {
    #{$inset-inline-start}: 0;
  }
}


.nav-links__megaMenu--size--xs {
  width: 2 / 12 * 100%;
}

.nav-links__megaMenu--size--sm {
  width: 4 / 12 * 100%;
}

.nav-links__megaMenu--size--nl {
  width: 50%;
}

.nav-links__megaMenu--size--md {
  width: 8 / 12 * 100%;
}

.nav-links__megaMenu--size--lg {
  width: 10 / 12 * 100%;
}

.nav-links__megaMenu--size--xl {
  width: 100%;
}